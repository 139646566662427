import React, { Component } from "react";
import { Link } from 'gatsby';
import classNames from 'classnames';

import standaloneLogoImg from '../../assets/brand/standalone_logo.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState(prevState => ({ toggleMenu: !prevState.toggleMenu }));
  }

  render() {
    const { toggleMenu } = this.state;

    return (
      <header className="toptive-header">
        <nav className="navbar fixed-top navbar-dark bg-blueberry navbar-expand-md">
          <div className="container d-flex flex-row">
            {/* navbar brand visible only in desktop */}
            <Link className="navbar-brand d-none d-md-inline-block" to="/">
              <span className="bold">Toptive</span>
              <span className="">Developers.</span>
            </Link>
            {/* navbar toggler */}
            <div className="d-md-none fixed-width">
              <button
                aria-controls="toptive-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                className={classNames("navbar-toggler", { collapsed: !toggleMenu })}
                data-target="#toptive-navbar"
                data-toggle="collapse"
                style={{width: '30px', padding: '0px'}}
                type="button"
                onClick={this.toggleMenu}
              >
                <div className="navbar-button">
                  <div className="hl fs" />
                  <div className="hl sd" />
                  <div className="hl th" />
                  <div className="vl fs" />
                  <div className="vl sd" />
                </div>
              </button>
            </div>
            {/* navbar brand visible only in mobile */}
            <Link className="navbar-brand d-md-none m-0" to="/">
              <img alt="Toptive" className="d-inline-block align-middle" height={50} src={standaloneLogoImg} width={50} />
            </Link>
            {/* hire us button only in mobile */}
            <div className="d-md-none fixed-width">
              <ul className="navbar-nav d-md-none">
                <li className="nav-item">
                  <Link className="nav-link" to="/contact" activeClassName="active">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* navbar collapsible menu */}
            <div className={classNames("navbar-collapse", { collapse: !toggleMenu })} id="toptive-navbar">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/services" activeClassName="active">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about" activeClassName="active">
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/work" activeClassName="active">
                    Work
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/team" activeClassName="active">
                    Team
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact" activeClassName="active">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
