import React from "react";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer className="toptive-footer flex-fill bg-oldberry pt-5 pb-3" data-section="footer" data-section-anchor="false">
      <section className="container-fluid d-flex flex-column align-items-center my-3">
        <a className="footer-logo text-center" href="#top">
          <h1>
            Toptier
          </h1>
          <h3>
            developers
          </h3>
        </a>

        <nav className="mt-4 pt-2 mb-5">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About Us</Link>
            </li>
            {/*
              <li className="nav-item">
                <Link className="nav-link" to="/work">Work</Link>
              </li>
            */}
            <li className="nav-item">
              <Link className="nav-link" to="/team">Team</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact Us</Link>
            </li>
          </ul>
        </nav>

        <a className="footer-mail" href="mailto:info@toptive.co">
          info@toptive.co
        </a>
      </section>

      <section className="container-fluid pt-2 mt-5">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-left justify-content-md-center mb-5">
            <div className="footer-info text-left">
              <h1>
                Río Cuarto (ARG)
              </h1>
              <h2 className="mt-4">
                Av. Italia 1684
                <br />
                Río Cuarto, Córdoba, CX5800.
                <br />
                <a href="tel:5493585064297">
                  +54 9 (358) 5064297
                </a>
              </h2>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-left justify-content-md-center mb-5">
            <div className="footer-info text-left">
              <h1>
                Buenos Aires (ARG)
              </h1>
              <h2 className="mt-4">
                Moldes 2924
                <br />
                Ciudad Autónoma de Buenos Aires, C1429AFB.
                <br />
                <a href="tel:541145442718">
                  +54 (11) 45442718
                </a>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <hr className="toptive-ruler" />

      <section className="container-fluid">
        <div className="footer-info text-center">
          <nav className="mt-4 pt-2 mb-2">
            <ul className="nav nav-social justify-content-center">
              <li className="nav-item">
                <a className="nav-link" href="https://www.facebook.com/toptive" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook-square" />
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.instagram.com/toptierdevelopers/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram" />
                  Instagram
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://medium.com/@toptive" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-medium" />
                  Medium
                </a>
              </li>
              {/*<li className="nav-item">
                <a className="nav-link" href="skype:live:info_931495?chat" target="_self" rel="noopener noreferrer">
                  <i className="fab fa-skype" />
                  Skype
                </a>
              </li>*/}
            </ul>
          </nav>
          <h4 className="mt-4">&copy; 2019 Toptive</h4>
        </div>
      </section>
    </footer>
  );
}
